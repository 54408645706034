@import 'styles/theme/theme';

.testimonials {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;

  &__header {
    padding: 0 42px;
    &__logoWrapper {
      height: 50px;
    }
  }

  &__title {
    margin-bottom: 16px;
    text-align: center;
  }

  &__trustedSection {
    padding: 0 4px;
    width: 100%;
    @media (max-width: 600px) {
      padding: 0 15px;
    }

    &_divider {
      border-width: 0;
      border-style: solid;
      border-color: $borderGrey;
      border-bottom-width: 0;
      border-right-width: thin;
    }

    &_grid {
      width: 90%;
      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        width: 100%;
      }
    }

    &_logo {
      filter: grayscale(1);
    }

    &_gridItem {
      display: flex;
    }
  }

  &__testimonialsSection {
    padding: 0 42px;
    @media (max-width: 600px) {
      padding: 0 15px;
    }
  }

  &__item {
    margin-bottom: 20px;
  }
}

.logoImage {
  margin: auto 0;
  width: 160px;
  height: 16px;
}
