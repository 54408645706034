.loader {
  text-align: center;
  margin-top: 100px;

  @media (max-width: 760px) {
    padding: 16px;
  }

  &__title {
    margin-top: 20px;
    line-height: 3;
  }
}

.loaderModal {
  margin-top: 0;
  padding: 20px;
}