@import 'styles/theme/theme';

.layout {
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
  &__right {
    width: 33%;
    background-color: $orangeLight;
    border-right: 1px solid $borderGrey;
    min-height: 100%;
    flex-grow: 2;
    padding-top: 20px;

    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .login {
    width: 67%;
    height: 100vh;
    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
    }
    background-color: $backgroundGrey;
    border-left: 1px solid $borderGrey;
    .mobileBack {
      top: 30px;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 52px;
      border-bottom: 1px solid $borderGrey;
      height: 64px;

      .leftSection {
        display: flex;
        align-items: center;
        .backButton {
          position: relative;
          top: 0;
          left: 0;
        }
        .backLink {
          height: 20px;
        }
        .proLogoDesktop {
          display: flex;
          gap: 2px;
          align-items: flex-start;
          justify-content: center;
          cursor: pointer;
          position: relative;
          flex-wrap: wrap;
        }
      }

      .rightSection {
        display: flex;
        align-items: center;
        .languageSelector {
          margin: 7px 0 7px 10px;
        }

        .headerLanguageSelector {
          height: 100% !important;
        }

        .mobileLanguageSelector {
          background: transparent;
          color: #757171;
        }

        .loginLink {
          font-weight: bold;
          text-transform: uppercase;
          color: $blueChip;
          letter-spacing: 1px;
          padding: 7px 10px;
          margin: 1px 0;
        }
      }
    }
  }
}

.listWorks {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  position: relative;
  li {
    list-style: none;
    margin-right: 10px;
    position: relative;
    padding-left: 20px;
    text-transform: uppercase;
    font-weight: 500;
    &:not(:first-child)::before {
      content: '•';
      position: absolute;
      color: $orange;
      left: 0;
      top: 50%;
      bottom: -3px;
      transform: translateY(-50%);
      font-size: 12px;
    }
  }
}
