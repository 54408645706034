@use 'styles/theme/theme' as theme;

.modalContainer {
  width: 463px !important;
  position: static !important;
  max-height: 620px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
  border-radius: 16px;
}
.closeIcon {
  top: 9px !important;
}

.modalRoot {
  padding: 50px 0;
  z-index: 100000;
}

.drawerContainer {
  min-height: 95%;
}

.disabled {
  pointer-events: none;
}

.drawerRoot {
  z-index: 99999;
}

.content {
  border-bottom: 1px solid theme.$borderGrey;
}

.modalFooter {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  border-radius: 4px;
  border: 1px solid theme.$whiteSmoke;

  &__customerLogo {
    gap: 8px;
    display: flex;
    margin-top: 12px;
    text-align: center;
    flex-direction: column;
    width: 100%;
  }
}

.trustedByStrip {
  border-bottom: 1px solid theme.$whiteSmoke;
  padding: 12px 14px 12px 0px;
}

.testimonialContainer {
  > div {
    gap: 0;
    > div {
      padding: 16px 6px;
      border-bottom: 1px solid theme.$whiteSmoke;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
